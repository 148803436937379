import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PageTitle from "../components/pagetitle"

const FamilyLaw = () => (
  <Layout>
    <PageTitle title="Family Law and Divorce Attorney" page="PageTitleFamily" />
    <Seo title="Family Law and Divorce Attorney in Annapolis MD" description="At The Law Office of Sean E Becker, LLC we provide an understanding ear as well as over 10 years of family law experience to help you through your situation. " />
    <div className="content-page-wrapper">
        <div className="page-intro">
            <p>
            When the foundations of your family change, it's never easy. The legal challenges involved in divorce, custody and other family law matters can be difficult and confusing. Add to that the often-wrenching emotions that can accompany such changes, and you have a recipe for high stress and conflict. At The Law Office of Sean E Becker, LLC we provide an understanding ear as well as over 10 years of family law experience to help you through your situation. We can take some of the fear and uncertainty out of the process, guiding you step by step with compassion to your next phase of life. Sean works closely with his clients to explain all avenues of the process and work towards meeting his client's expectations. Family law matters can be lengthy and stressful and Sean does all he can to make this process as smooth as possible for his clients.
            </p>            
        </div> 
    </div>        
  </Layout>
)

export default FamilyLaw
